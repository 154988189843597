$base-font-serif: 'Figtree', sans-serif !default;
$base-font-sans-serif: 'Figtree', sans-serif !default;

$base-color-primary-main: #383b59;
$base-color-secondary-main: darken(#383b59, 5%);
$base-color-accent-main: #ee7a04;
$base-color-accent-secondary: #ee7a04;

@import '../Default/theme';

:root {
  /**
   * Classic Header -> src/components/headers/ClassicHeader/index.tsx
   */
  --classic-header-logo-height: 50px;

  @include mq($from: desktop) {
    --classic-header-logo-height: 60px;
  }

  /**
   * Classic Footer -> src/components/footers/ClassicFooter/index.tsx
   */
  --classic-footer-background-color: #{$base-color-primary-main};
}
